import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <body >
      <nav>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>NOTEVERSE</h1>
        <div >
          <ul>
            <li><a href="https://abhicracker.com">Home</a></li>
            <li><a href="https://abhicracker.com">About</a></li>
            <li><a href="https://abhicracker.com">Contact</a></li>
          </ul>
        </div>
      </nav>
      {/* <h1>NOTEVERSE</h1> */}

    </body>
  );
}

export default App;
